import { PROVIDER_PLANS } from './providerPlansTypes';

const initialState = {
   providerPlansList: [],
   success: false,
   loading: true,
   registered: false,
   error: false
}

const PrroviderPlanReducer = (state = initialState, action) => {

   switch (action.type) {
      case PROVIDER_PLANS:
         return {
            ...state,
            providerPlansList: action.payload,
            success: true,
            loading: false,
            registered: true
         }

      default:
         return state
   }
}

export default PrroviderPlanReducer
