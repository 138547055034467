
import React, { useEffect } from "react";
import { Col, Row, Image } from "react-bootstrap";
import { Regions } from '../../../redux/regions/regionActions';
import { useDispatch, useSelector } from "react-redux";
import regionimg from "../../../assets/images/region-img.png";
import { Spinner } from "../../Common/Spinner/Spinner";
import { Link } from "react-router-dom";

const RegionsListInfo = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(Regions());
    }, [dispatch]);
    
    const regions = useSelector((state) => state?.regions?.regionsList?.data);
    const Loading = useSelector((state) => state?.regions?.loading)
    return (
        <div className="about">
            {Loading ? <Spinner /> :
                <>
                    <div className="container-width">
                        <div className="about-content text-center mb-5">
                            <h2 className="position-relative orange">Regions</h2>
                            <p>Proin ex ipsum, facilisis id tincidunt sed, vulputate in lacus. Dune Donec pharetra faucibus leo.</p>
                        </div>
                        <div className="about-boxes">
                            <Row>
                                <Col lg="12">
                                    <Row>
                                        {
                                            regions?.length > 0 &&
                                            regions?.map((item, index) => (
                                                <Col lg="6" className="mb-3">
                                                    <Link to={`/esim/${item.slug}`}>
                                                        <div className="about-country-flags d-flex align-items-center justify-content-between">
                                                            <p>{item.name}</p>
                                                            <div className="about-country-flags-img about-country-flags-img-globel about-region-flag">
                                                                <Image src={regionimg} fluid />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>


            }
        </div>
    )
}
export default RegionsListInfo;