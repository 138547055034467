import { PROVIDER_PLANS, ERROR_STATE } from "./providerPlansTypes";
import { apiHelper } from "../apiHelper"
import 'react-toastify/dist/ReactToastify.css';

export const ProviderPlans = (slug, provider) => async (dispatch) => {

  try {

    const bodyParameters = {
      provider: provider,
      slug: slug
    }

   



    let res = await apiHelper("post", `fetch-provider-plans`, bodyParameters)

    if (res?.data) {
      if (res?.data) {
        let { data } = res
        dispatch({
          type: PROVIDER_PLANS,
          payload: data
        })

      }
      else {
        dispatch({
          type: ERROR_STATE
        })
      }
    }


  } catch (error) {
    console.log(error);
  }
}

