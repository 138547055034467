import React  from "react";
import { useSelector } from "react-redux";
import { Col, Image, Row } from "react-bootstrap";
import { Spinner } from "../../Common/Spinner/Spinner";

const Banner = () => {
    const Loading = useSelector((state) => state?.providerPlans?.loading)
    const provider = useSelector((state) => state?.providerPlans?.providerPlansList?.data?.provider_details)
    return (
        <div className="mogo-banner position-relative">
            <div className="container-width">
                {
                    Loading ? <Spinner /> :
                        <Row>

                            <Col lg="6" className="mb-lg-0 mb-0 d-flex align-items-center">
                                {provider ?
                                    <div className="mogo-banner-content-wrapper d-lg-flex d-md-flex d-sm-flex">
                                        <div className="mogo-banner-icon me-lg-5 me-md-5 me-sm-4 me-0">
                                            <Image src={provider?.logo} fluid />
                                        </div>
                                        <div className="mogo-banner-content">
                                            <h2 className="mb-0">{provider.title} <span className="orange">eSIM</span></h2>

                                            <p>{provider?.description}</p>
                                            <a href={provider?.websiteLink} target="_blank" rel="noreferrer" className="btn orange-btn-large d-inline-flex align-items-center" >
                                                <div className="mogo-official-img me-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32.738" height="32.813" viewBox="0 0 32.738 32.813">
                                                        <path id="link-svgrepo-com" d="M19.326,25.861l2.053-2.084A6.464,6.464,0,0,1,17.01,22a6.26,6.26,0,0,1-.015-9.2l5.033-5.033a6.231,6.231,0,0,1,9.186,0,6.231,6.231,0,0,1,.015,9.186L28.2,19.979a5.789,5.789,0,0,1,.371,3.134L33.1,18.59A8.6,8.6,0,0,0,33.082,5.9a8.6,8.6,0,0,0-12.691-.015l-5.265,5.28a8.6,8.6,0,0,0,.015,12.691A7.262,7.262,0,0,0,19.326,25.861Zm.386-12.722-2.053,2.084A6.537,6.537,0,0,1,22.027,17a6.26,6.26,0,0,1,.015,9.2l-5.048,5.033a6.243,6.243,0,0,1-9.186.015,6.248,6.248,0,0,1,0-9.2l3.026-3.026a5.781,5.781,0,0,1-.386-3.134L5.925,20.411A8.622,8.622,0,0,0,5.94,33.1a8.61,8.61,0,0,0,12.691.031l5.28-5.3A8.6,8.6,0,0,0,23.9,15.147,7.262,7.262,0,0,0,19.712,13.14Z" transform="translate(-3.148 -3.095)" fill="#fff" />
                                                    </svg>
                                                </div>
                                                Official Website
                                            </a>
                                        </div>
                                    </div>
                                    : ""}
                            </Col>
                            <Col lg="6">
                                {/* <div className="mogo-banner-img d-flex justify-content-lg-end justify-content-md-center ">
                                    <Image src={mogobannerimg} fluid />
                                </div> */}
                            </Col>
                            {/* <div className="mogo-banner-img-mobile">
                                <Image src={mogobannerimg} fluid />
                            </div> */}
                        </Row>
                }
            </div>
        </div>
    )
}
export default Banner;