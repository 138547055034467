import React from "react";
import { Range, getTrackBackground } from "react-range";



function FilterSlider(props) {
  const STEP = props.step;
  const VALUES = props.range;
  const MIN = props.min;
  const MAX = props.max;

 

  function handleChange(values) {

    props.onChange(values);
    
  }

  return (
    <>
      <h6>{props.title}</h6>
      {
        props.title === "Price" ?
          < output style={{ display: "flex", justifyContent: "space-between", width: "100%" }} id="output">
            <div style={{ fontWeight: "bold", fontSize: "18px" }}>{props.min_symbol} {VALUES[0]} </div>
            <div style={{ fontWeight: "bold", fontSize: "18px" }}>{props.mix_symbol} {VALUES[1]} {props.plus_symbol}</div>
          </output>
          :
          < output style={{ display: "flex", justifyContent: "space-between", width: "100%" }} id="output">
            <div style={{ fontWeight: "bold", fontSize: "18px" }}>{Math.floor(VALUES[0].toFixed(1))} {props.plus_symbol} {props.min_symbol}</div>
            <div style={{ fontWeight: "bold", fontSize: "18px" }}>{Math.floor(VALUES[1].toFixed(1))} {props.plus_symbol} {props.mix_symbol}</div>
          </output>
      }
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          margin: "15px"
        }}
      >
        <Range
          values={VALUES}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(VALUES) => handleChange(VALUES)}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%"
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: VALUES,
                    colors: ["#ccc", "#fa6400", "#ccc"],
                    min: MIN,
                    max: MAX
                  }),
                  alignSelf: "center"
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "42px",
                width: "42px",
                borderRadius: "50%",
                backgroundColor: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA"
              }}
            >
              <div
                style={{
                  height: "16px",
                  width: "5px",
                  backgroundColor: isDragged ? "#fa6400" : "#CCC",
                }}
              />
            </div>
          )}
        />
      </div>
    </>
  );
}

export default FilterSlider;