import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useParams } from 'react-router-dom'
import { useSelector } from "react-redux";
import { Image } from "react-bootstrap";

const PlanOffered = () => {
    const providerPlans = useSelector((state) => state?.providerPlans?.providerPlansList?.data)
    const other_providers = providerPlans?.other_provider_list
    const isCountryPlan = providerPlans?.is_country_providers
    const countryDetail = providerPlans?.country_details
    const regionDetail = providerPlans?.region_details
    const url = useParams();

    return (

        <div className="plan-offers">
            {other_providers?.length > 0 ?
                <>
                    <div className="plan-buy-heading text-center">
                        <h4 className="orange mb-lg-5 mb-md-5 mb-4 text-uppercase">{isCountryPlan === 1 ? countryDetail?.name : regionDetail?.name} ALSO COVERED BY</h4>
                    </div>
                    <div className="container-width">
                        <div className="plan-offers-content-area">
                            <div className="plan-offers-content-area-top">
                                {

                                    other_providers?.map((item, index) => (
                                        <>
                                            <a href={"/esim/"+url.slug + "/"  + item.providerSlug}>
                                                <div className="plan-offers-content mb-2 d-flex flex-lg-row flex-md-row flex-sm-row flex-column  justify-content-between">
                                                    <div className="plan-offers-content-left d-flex align-items-center justify-content-center mb-lg-0 mb-md-0 mb-md-0 mb-3">
                                                        <div className="plan-offers-content-left-img me-3">
                                                            <Image src={item.providerLogo} fluid />
                                                        </div>
                                                        <p>{item.providerTitle}</p>
                                                    </div>
                                                    <div className="plan-offers-content-right d-flex align-items-center justify-content-center">
                                                        <p className="me-3">{item.totalPlans} data plans offered</p>
                                                        <FontAwesomeIcon icon={faAngleRight} className="orange" />
                                                    </div>
                                                </div>
                                            </a>
                                        </>
                                    ))
                                }
                            </div>
                            {/* <div className="plan-offers-content-area-bottom text-lg-center text-md-center text-start d-flex align-items-lg-center align-items-start justify-content-center">
                                <div className="checked-tick me-3">
                                    <Image src={checkedtick} fluid />
                                </div>
                                <h6 className="mb-0">Data plans for the provider(s) with this icon are programmatically fetched and updated</h6>
                            </div> */}
                        </div>
                    </div>
                </>
                : ""}
        </div>
    )
}
export default PlanOffered;