import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-select-search/style.css'
import Home from './Components/Home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Provider from './Components/Provider/Provider';
import Privacy from "./Components/Privacy/Privacy";
import RegionsList from "./Components/RegionsList/RegionsList";
import Plans from "./Components/Plans/Plans";
import CountryList from "./Components/CountryList/CountryList";
import Terms from "./Components/Terms/Terms";
import Support from "./Components/Support/Support";
import Contactus from "./Components/Contactus/Contactus";
import { useSelector } from "react-redux";
import {Helmet} from "react-helmet";
import PageNotFound from './Components/PageNotFound/PageNotFound'
function App() {
	const settingData = useSelector((state) => state?.settings?.settingsData?.data?.settings)
	const plans = useSelector((state) => state?.plans?.plansList?.area)
	const providerPlans = useSelector((state) => state?.providerPlans?.providerPlansList?.data)
	var link = window.location.href.split('/')
	const option = link[3] === 'esim' ? (plans === null ? (providerPlans?.country_details === '' ? providerPlans?.region_details : providerPlans?.country_details): plans) : settingData
	
	return (
		<div className="App">
			<Helmet>
				<title>{settingData?.site_title}</title>
				<meta name="title" content={option?.meta_title} />
				<meta name="keyword" content={option?.meta_keyword} />
				<meta name="description" content={option?.meta_description} />
			</Helmet>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/esim/:slug" element={<Provider />}   />
					<Route path="/privacy-policy" element={<Privacy />} />
					<Route path="/esim/:slug/:provider" element={<Plans />} />
					<Route path="/regions" element={<RegionsList />} />
					<Route path="/region/:slug" element={<CountryList />} />
					<Route path="/terms-and-conditions" element={<Terms />} />
					<Route path="/support" element={<Support />} />
					<Route path="/contact-us" element={<Contactus />} />
					<Route path="*" exact={true} element={<PageNotFound />} />
					
				</Routes>
				
			</BrowserRouter>
		</div>
	);
}

export default App;
