import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import About from "./About/About";
import AboutInfoBoxes from "./AboutInfoBoxes/AboutInfoBoxes";
import Banner from "./Banner/Banner";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
const Home = () => {
    // Scroll to Top on page Load
    const TRACKING_ID = "G-M8PECVFRLQ"
    ReactGA.initialize(TRACKING_ID);
    useEffect(() => {
        window.scrollTo(0, 0)
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])
    return(
        <div className="wrapper">
            <Header/>
            <Banner/>
            <About/>
            <AboutInfoBoxes/>
            <Footer/>
        </div>
    )
}
export default Home;