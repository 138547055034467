import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import bannerSearch from '../../../assets/images/banner-search.svg';
import { SearchRegionAndCountries } from '../../../redux/searchRegionAndCountries/searchRegionAndCountriesActions';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import Select from 'react-select';
const Banner = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(SearchRegionAndCountries());
    }, [dispatch]);
    const options = useSelector((state) => state?.countriesAndRegion?.searchRegionAndCountriesList?.data);

    const handleClick = (e) => {
        navigate(`/esim/${e.value}`);

    }

    return (
        <div className="banner">
            <div className="container-width">
                <div className="banner-content text-center">
                    <h3>Find the best prepaid eSIM plans for your</h3>
                    <h1>TRAVEL <span className="orange">DESTINATION</span></h1>
                    <div className="banner-form">
                        <h6 className="mb-2">Search and compare 2000+ data plans from 25+ eSIM providers</h6>
                        <div className="input-wrapper-area">
                            <div className='input-wrapper position-relative'>
                                <div className="banner-search-img position-absolute">
                                    <Image src={bannerSearch} fluid />
                                </div>
                                <Select classNamePrefix="custom"
                                    placeholder="Select Country or Region"
                                    options={options}
                                    onChange={handleClick}
                                    formatOptionLabel={country => (
                                        <div className="country-option country-search d-flex align-items-center">
                                            <Image src={country.image} alt="country-image" />
                                            <span>{country.label}</span>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Banner;