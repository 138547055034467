
import homeReducer from "./home/homeReducer";
import regionReducer from "./regions/regionReducer";
import regionCountriesReducer from "./regionCountries/regionCountriesReducer";
import settingsReducer from "./settings/settingReducer";
import cmsPagesReducer from "./cmsPages/cmsPagesReducer";
import planReducer from "./plans/planReducer";
import providerPlansReducer from "./providerPlans/providerPlansReducer";
import contactUsReducer from "./contactUs/contactUsReducer";
import ourHelpReducer from "./ourHelp/ourHelpReducer";
import searchCountriesAndRegionReducer from "./searchRegionAndCountries/searchRegionAndCountriesReducer";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk'
const middleware = [thunk]

const reducer = combineReducers({
  home: homeReducer,
  regions: regionReducer,
  regionCountries: regionCountriesReducer,
  settings:settingsReducer,
  plans:planReducer,
  providerPlans:providerPlansReducer,
  contactUs:contactUsReducer,
  cmsPages:cmsPagesReducer,
  ourHelp:ourHelpReducer,
  countriesAndRegion : searchCountriesAndRegionReducer
});

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store


